$jp-mob-max: '#{$screen} and (max-width: 530px)' !default;
$jp-medium-min: '#{$screen} and (min-width: 768px)' !default;
$jp-medium-max: '#{$screen} and (max-width: 768px)' !default;

.pg_wrapper {
  margin: 0;
  overflow: visible;
}

.device-mobile a input {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0;
}

form label {
  display: block;
  font-size: 14px;
  margin: 0 0 8px;
}
/* Override cl_base.... */
#payment.device-mobile .form_element label {
  text-transform: none;
  width: 190px;
  display: block;
  font-size: 1em;
}

#payment.device-mobile .form_element.default_payment_container label {
  text-transform: uppercase;
}

#payment.device-mobile h2 {
  font-size: 30px;
}

.device-mobile .form_element.radio label,
.device-mobile .form_element.checkbox label {
  display: inline !important; /* :( */
}

.device-mobile a.add-address-link input {
  width: 100%;
}

.device-mobile .column .controls .payment-details {
  float: left;
  margin: 5px 5px 0 0;
}

#main.single {
  .panel {
    a.change-address {
      color: #5dba98;
    }
  }
}

#msg-panel {
  background-color: #5dba98;
  padding: 10px;
}

#msg-panel p {
  color: white;
  font-family: 'HelveticaNeueLTStd45Light', Arial, Sans-Serif;
  font-size: 19px;
  margin: 0;
}

// #main.single {
//   .panel {
//     header.viewcart-header .item-count { font-size: 20px; float:right;  padding:0 !important;}
//     header.viewcart-header .item-count:after { content: " items"; }
//     header.viewcart-header .item-count:before { content: ""; }
//     header .item-count { font-weight: bold; }
//     header.viewcart-header .item-count { padding-top: .5em; }
//     header.viewcart-header h2 {  font-size: 20px !important; }
//     .content { padding: 0; }
//   }
//   .product {
//     .color { width: 100% !important; float:left !important; }
//   }
//   #viewcart-panel .qty { text-align: center; }
//     .messages {
//       background-color: transparent;
//       border: 0;
//       font-size: inherit;
//       font-family: inherit;
//       font-weight: normal;
//       .single-message {
//         color: #ef6ea8;
//         margin-bottom: 5px;
//         background: none;
//         border: none;
//         padding: 0;
//         font-size: 19px;
//         font-family: "HelveticaNeueLTStd45Light",Arial,Sans-Serif;

//       .close_link {
//         display: none;
//       }
//     }
//       .single-message:first-child {
//         margin-top: 10px;
//       }
//       .single-message.INFORMATION {
//         color: #cc9942;
//       }
//     }
// }
.mobile_mpp {
  .not_shaded_sized {
    .spp_product_status {
      .coming_soon_stock_msg {
        a.btn-tempoutofstock {
          font-size: 10px;
          line-height: 35px;
        }
      }
    }
  }
}
/* Account */
#signin {
  .show_password-form-container {
    label {
      display: inline-block;
    }
  }
}

#registration {
  #newsletter-info {
    fieldset.email_promotions {
      h3 {
        margin-bottom: 0.5em;
      }
      .form_element.sms_promo_container {
        label {
          a {
            display: inline-block;
          }
        }
      }
      .phone_container {
        margin-top: 0.5em;
        span.prefix {
          display: block;
          float: left;
          line-height: 2.5em;
        }
        input.sms_input {
          float: left;
          width: auto !important; /* Yuck! DH */
          margin-left: 1em;
        }
      }
    }
  }
}

#address_book {
  .body_container #content .payment_book_container {
    border: 0;
  }
  .address-book-info {
    .add-address-link,
    .column {
      margin: 0 0 20px;
      display: block;
    }
  }
  .add_new_creditcard {
    padding: 0;
    margin: 0;
    .add-address-link {
      margin: 0 0 20px;
      display: block;
    }
  }
  .payment_book_container .column {
    margin: 0 0 20px;
  }
  .account-header-panel {
    h3 {
      margin: 0;
    }
  }
}

#payment {
  .main_container #myaccount-wrapper {
    padding: 0 10px 20px;
  }
  fieldset.fs,
  fieldset.payment_address {
    margin: 0 10px 0 0;
    width: auto;
  }
  .checkbox {
    input {
      width: auto;
    }
    input,
    label {
      display: inline;
    }
  }
}

#payment_info {
  fieldset.fs,
  fieldset.payment_address {
    margin: 0 10px 0 0;
    width: auto;
  }
  .page-header,
  .add_payment_link,
  .column {
    margin: 0 0 20px;
    display: block;
  }
  .billing_address,
  .controls {
    margin: 10px 0 0;
  }
}

#past_purchases {
  .account-past-purchases {
    .shipment-products .product-header {
      display: none;
    }
    .past-purchases {
      .order-info {
        &.accordion-content {
          .products {
            .product-footer {
              .gift_wrap,
              .cod_cost {
                border-bottom: 1px solid #d3d3d3;
              }
              .total {
                border-top: 1px solid #333;
              }
              .sales_tax {
                border-bottom: 1px solid #333;
              }
            }
          }
        }
      }
    }
  }
}

/* Samples page */
body#samples #samples-panel .btn {
  @extend input.button[type='submit'];
}

body#samples #samples-panel .add-button {
  display: none;
}

body#samples .panel {
  padding: 10px 15px;
}

body#samples .loading {
  background: url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif) no-repeat center center;
}

body#samples .loading * {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
}
/* Panels */
#viewcart #promo-panel .banner img {
  width: 100%;
}

@media #{$jp-medium-max} {
  div#main.single {
    section#viewcart-panel header.viewcart-header {
      display: block;
      border-bottom: 1px solid #dbdbdb;
      padding-right: 0px;
      h2 {
        float: none;
      }
      .item-count {
        text-align: left;
        margin: -15px;
        padding: 0 20px 0;
      }
    }
  }
  #samples-panel {
    #checkout_samples {
      .samples-buttons {
        .return-link {
          display: none;
        }
      }
      .samples {
        .mobile_hidden {
          display: none;
        }
      }
    }
  }
  .viewcart-buttons-panel {
    .content {
      .edit-cart {
        width: 50%;
        float: left;
      }
      .continue-buttons {
        float: right;
        .continue-checkout {
          font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
          background-color: #5dba98;
          text-transform: uppercase;
          color: white;
          line-height: 1.5;
          font-size: 0.9em;
          text-align: center;
          letter-spacing: 0;
          border: 0;
          padding: 4px 10px 3px;
          display: inline-block;
          zoom: 1;
          white-space: nowrap;
        }
      }
    }
  }
}

@media #{$jp-medium-min} {
  .right {
    .ship-method-group {
      .delivery-note {
        margin: 4% 0 0 0;
        float: left;
      }
      #form--checkout_shipmethod--field--SHIP_METHODS_HOME--index--11 {
        width: 96%;
      }
      .date-list {
        padding: 0em !important;
      }
      .form-item {
        label {
          float: left;
          margin: 0 10px 0 0 !important;
        }
        .label-content {
          display: inline-block;
        }
        float: right;
        margin: 10px 0 0 0 !important;
      }
    }
  }
  .left {
    .ship-method-home-group {
      .form-item {
        label {
          float: left;
        }
        .label-content {
          display: inline-block;
        }
        float: right;
      }
    }
  }
  #viewcart {
    .left {
      .ship-method-group {
        .delivery-note {
          max-width: 100% !important;
          float: right;
        }
      }
    }
  }
}

.footer-top-mobile-v1 .form-text-input {
  margin-right: 0;
  width: 82%;
}

.footer-top-mobile-v1 .form-text-span {
  float: left;
  margin-top: 20px;
  width: 18%;
}

#foreground-node {
  width: 100% !important;
  height: auto !important;
  div.cms_layer {
    width: 100% !important;
    position: relative !important;
    top: 0px !important;
  }
  table {
    width: 90% !important;
  }
}

#footer {
  height: auto !important;
  margin: -1px !important;
}

#main.single #guarantee-panel {
  background-color: #fff !important;
  h2 {
    font-weight: normal;
    font-size: 15px !important;
  }
}

.deactivate {
  .product-img img,
  label {
    opacity: 0.3;
  }
  .product-name {
    color: #cccccc !important;
    cursor: default !important;
  }
  .sample-select-button {
    cursor: default !important;
  }
}

body#samples {
  #main.single {
    .viewcart-buttons-panel {
      .edit-cart {
        margin-top: 13px;
      }
    }
    .samples-panel {
      p.available {
        margin-top: 0;
      }
      .product-img {
        margin-right: 0;
      }
    }
  }
}

body#viewcart {
  #main.single {
    #viewcart-panel {
      header {
        h2.viewcart-panel__title {
          font-size: 20px;
        }
      }
    }
    .viewcart-buttons-panel {
      .content {
        float: left;
        width: 100%;
        padding: 0px;
        .continue-buttons {
          width: 100%;
          a.paypal-checkout {
            float: left;
            width: 50%;
            padding: 10px 0 10px 0;
            text-align: center;
          }
          span.or {
            float: left;
            width: 5%;
            text-align: center;
            font-size: 12px;
            margin-top: 20px;
            @media #{$jp-mob-max} {
              margin-top: 10px;
              display: block;
            }
          }
          a.continue-checkout {
            float: right;
            width: 34%;
            margin-top: 7px;
            padding: 2px 0 2px 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.power-couples-formatter-v1 {
  .accordionItem.power-couples-row-mobile-v1 {
    h3 span.Anti-Blemish {
      background-position: -2px 4px;
      height: 40px;
    }
    h3 span.De-ageing {
      background-position: 0 -74px;
      height: 40px;
    }
  }
}

#header {
  .gnav-search-mobile-v1 .search-icon,
  .gnav-utility-button-v1 .utility-link,
  .gnav-drop-down-v1 .dd-icon .link {
    img {
      height: 56px;
    }
  }
}

#live_chat {
  .chat_items {
    div.chat_product_queries {
      width: 100%;
      text-align: center;
      border-right: none;
    }
  }
  #chat_unavailable {
    .phone_icon,
    .mail_icon {
      width: auto;
    }
  }
  div#chat_window {
    .server {
      span.text {
        a {
          color: #000000;
          font-family: sans-serif;
        }
      }
    }
    .client {
      span.text {
        a {
          font-family: sans-serif;
        }
      }
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp-product {
      .product-info {
        .ppu {
          padding-top: 6px;
        }
      }
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp-product {
      .shaded {
        .ppu {
          padding-bottom: 12px;
        }
      }
    }
  }
}

.content {
  .spp_view {
    .spp-product {
      .mpp_product {
        .ppu {
          margin-left: 15px;
          padding-top: 6px;
        }
      }
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp-product {
      .product-info {
        p.price {
          width: auto;
        }
      }
    }
  }
}

.device-mobile {
  .product.spp-product {
    .mpp_product {
      p.price {
        width: auto;
      }
    }
  }
}
/* The Wink */
div.social_share_links {
  p.social_icons {
    a.js-canvas-add {
      pointer-events: none;
    }
  }
}

body.section-thewink {
  div.sticky-tab {
    z-index: 100;
  }
}

#viewcart {
  #main.single {
    #viewcart-panel {
      .content {
        .product {
          a.remove-donation.remove_btn {
            padding: 25% 0% 0% 0%;
            display: block;
          }
        }
      }
    }
  }
}

#sign-in-panel {
  #new-account {
    p.required {
      margin: 0px 20px;
      font-size: 10px;
    }
  }
  #return-user {
    p.required {
      margin: 0px 20px;
      font-size: 10px;
    }
  }
}

#main.single {
  #offer-code-panel {
    #offer_code {
      input[type='text'] {
        height: 2.6em !important;
        margin-top: 0px;
      }
    }
  }
}

#main.single {
  .sign-in-panel {
    #new-or-returning-radios {
      fieldset {
        padding-right: 0;
      }
    }
  }
}

.product-name {
  .donation-items {
    font-family: $base-font-family;
    color: $color-cl-soft-black;
  }
}

#viewcart {
  #main.single {
    #viewcart-panel {
      .content {
        .product.donation {
          .product_name {
            font-family: $base-font-family;
            color: $color-cl-soft-black;
          }
          .total.column {
            margin-top: -33px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

#past_purchases.device-mobile {
  .past-purchases {
    .products {
      .product-footer {
        .donation {
          text-transform: none;
          width: 100%;
          padding: 5px 0px 5px 0px;
          border-bottom: 1px solid #d3d3d3;
          span:last-child {
            float: right;
          }
        }
        .discount {
          text-transform: none;
          width: 100%;
          padding: 5px 0px 5px 0px;
          border-bottom: 1px solid #d3d3d3;
          span:last-child {
            float: right;
          }
        }
        .giftcard_redemption {
          border-bottom: none;
        }
      }
    }
  }
}

#giftcard_balance {
  .checkbox {
    .label-content {
      display: inline-block;
    }
  }
}

.panel select.error,
.panel select.checkedEmpty,
.panel select.touched {
  border-color: red !important;
}
/* overwride title to updated font */
.title {
  font-family: $base-font-family;
}

div #waitlist_christmas {
  text-align: center;
  h2 {
    font-size: 35px;
    color: #000000;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 23px;
    color: #000000;
  }
  input.form-text {
    height: 45px;
    width: 50%;
    border: 1px solid black;
  }
  input.form-submit.button {
    width: 100px;
    height: 45px;
    vertical-align: middle;
    margin-top: -1px;
  }
  .thankyou {
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .form-submit-wrap {
    margin-top: 20px;
  }
}

#replenish.device-mobile.device-mobile {
  .auto-replenishment-items {
    .frequency {
      .refill_select {
        display: none;
      }
    }
  }
}

#item-details {
  .frequency {
    .refill_select {
      display: none;
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  height: 136px;
  line-height: 136px;
  background: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE_Japanese.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0;
}

#top #header {
  .mobile-checkout-back {
    display: none;
  }
  .btn_navigation,
  .block-nodeblock-10 {
    position: static;
  }
  .site-logo-responsive-v1 {
    padding: 0;
  }
}

#main.single {
  .samples-panel {
    .samples {
      .mobile_hidden {
        display: none;
      }
    }
  }
}

section {
  &.clinique-spinner &#game-win-box {
    display: block;
    .win-box-btns {
      display: block;
      .try-again-btn {
        margin-top: 15px;
      }
    }
    div {
      &.game-win-description {
        h2 {
          font-size: 30px;
        }
      }
    }
  }
}

// Samples Heading
.checkout {
  .samples-page {
    .samples-panel__heading {
      font-size: 16px;
      text-transform: none;
    }
    .samples-panel__promo-text {
      display: none;
      @include breakpoint($ab-small-down) {
        display: inline-block;
      }
    }
    .samples-panel__promo-text.large {
      display: inline-block;
      @include breakpoint($ab-small-down) {
        display: none;
      }
    }
  }
}

#formatter {
  div {
    &#game-unavailable {
      div {
        &.pop-up-box-inner {
          align-items: baseline;
          h2 {
            font-size: 28px;
          }
          p {
            &.unavailable-terms {
              bottom: 25px;
            }
          }
        }
      }
    }
  }
}

.clinical-reality__modules--recommended-products {
  .ccr-flexslider {
    .flex-viewport {
      max-height: 790px;
    }
  }
}

.header-gnav-section__label,
.header-gnav-section__link {
  font-weight: bold;
}

.header-gnav-links__title {
  font-weight: bold;
}

.header-gnav-section__panel {
  .header-gnav-links {
    .header-gnav-link {
      display: initial;
    }
  }
}

.email-signup-block__header {
  .email-signup-block__headline {
    p {
      font-weight: bold;
    }
  }
}

.email-signup-block__form-content {
  .email-signup-block__form-cta,
  .email-signup-block__form-email {
    input {
      font-weight: bold;
    }
  }
}

.sitewide-footer-formatter__links {
  .footer-links__title {
    font-weight: bold;
  }
}

.header-gnav-cart {
  .cart-confirm-wrapper {
    .cart-overlay-products-heading,
    .left-copy,
    .subtotal {
      font-weight: bold;
    }
  }
}

body.device-mobile {
  .cart-confirm-wrapper {
    h2,
    b {
      font-weight: bold;
    }
  }
}

.section-store-locator {
  .state_container,
  .city_container {
    .sbHolder {
      height: auto !important;
      .sbOptions {
        overflow: auto !important;
        position: static !important;
      }
    }
  }
}

.gnav-header-formatter__sections-panel {
  padding-top: 77px !important;
}

#address_book {
  .outer-wrap {
    header {
      display: none;
    }
  }
}

.zdc {
  &__bg--display {
    width: 100%;
  }
  &__description {
    .zdc__offer--apply {
      .cta__button {
        padding: 15px;
      }
    }
  }
  &_popup {
    height: 83% !important;
    &.overlay-container {
      .zdc {
        &__popup {
          &--content {
            padding-top: 38px;
          }
          &--bg {
            height: 460px;
            .zdc {
              &__dollar--buttons {
                .cta {
                  &__button {
                    padding: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
